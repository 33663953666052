import React from "react";
import "./Footer.css";
import fd from "./fb-removebg-preview.png";
import linkedin from "./linkedin-removebg-preview.png";
import git from "./github-removebg-preview.png";

const Footer = () => {
  return (
    <div>
      <footer className="footer footer-center p-5 text-center   text-white">
        <div>
          <p>Copyright © 2024 Promote Flare</p>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
