import React from "react";
import { Link } from "react-router-dom";
// import img from "../asset/service/about.png";
import img from "../../../asset/service/about.png";

const AboutUs = () => {
  return (
    <div id="about" className="navbar py-10  my-10 lg:px-12 rounded-lg">
      <div className="hero bg-base-100 rounded-lg py-10">
        {" "}
        {/* Add rounded-lg here */}
        <div className="hero-content flex-col lg:flex-row">
          <img
            className="w-6/6 md:w-5/6 lg:w-5/12 rounded-lg"
            src={img}
            alt=""
          />{" "}
          {/* Add rounded-lg here */}
          <div className="lg:font-medium lg-1s/6">
            <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold text-primary">
              About us
            </h1>
            <p className="py-6 text-justify text-inherit">
              Promote Flare is your trusted partner for driving business success
              through innovative digital marketing and dynamic social media
              strategies. Our team of experts crafts tailored campaigns designed
              to boost brand awareness, increase engagement, and convert leads
              into loyal customers. Whether you're looking to enhance your
              online presence or accelerate business growth, Promote Flare
              delivers impactful, data-driven solutions that bring measurable
              results. Let’s ignite your brand’s digital potential!
            </p>

            <button
              style={{ backgroundColor: "#06e0e0" }}
              className="btn ml-3 font-bold text-white "
            >
              Request a Trail
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
