import React, { useState } from "react";

const Slider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const images = [
    {
      src: "https://images.unsplash.com/photo-1539571696357-5a69c17a67c6?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      alt: "Client 1",
      name: "Smith",
      designation: "Owner of a Fashion Boutique",
      text: "Working with PromoteFlare has completely transformed our social media presence. Their tailored content creation and ad campaigns have increased our online engagement by 50%! Our sales have skyrocketed, and we’ve gained a loyal following. Highly recommend their services!",
    },
    {
      src: "https://images.unsplash.com/photo-1499996860823-5214fcc65f8f?q=80&w=1966&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      alt: "Client 2",
      name: "James",
      designation: "Marketing Manager at a Tech Startup",
      text: "PromoteFlare has been an invaluable partner in our social media marketing strategy. Their expertise in analytics and reporting has helped us understand our audience better and fine-tune our campaigns. They’ve significantly boosted our brand awareness across Instagram and LinkedIn.",
    },
    {
      src: "https://images.unsplash.com/photo-1554727242-741c14fa561c?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      alt: "Client 3",
      name: "Linda",
      designation: "Founder of a Fitness Brand",
      text: "I’ve been working with PromoteFlare for the past six months, and I’ve seen tremendous growth in both followers and sales. Their team is professional, responsive, and always delivers creative content that resonates with our target audience. I’m beyond pleased with the results.",
    },
    {
      src: "https://images.unsplash.com/photo-1542596768-5d1d21f1cf98?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      alt: "Client 4",
      name: "Sarah",
      designation: "CEO of a Travel Agency",
      text: "PromoteFlare’s team took our social media to new heights. Their customized ad campaigns on Facebook and TikTok helped us reach potential clients in different markets, resulting in a noticeable increase in inquiries. Their approach is data-driven and highly effective!",
    },
  ];

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className="py-12 my-12 lg:px-12 rounded-3xl lg:px-24 lg:mx-12 p-5 bg-base-100">
      <h1 className="text-3xl md:text-4xl lg:text-5xl uppercase font-bold text-center py-12 text-primary">
        Happy client says ...!
      </h1>
      <div className="relative flex flex-col md:flex-row gap-8 justify-center items-center">
        {/* Display the current slide */}
        <div className="flex flex-col md:flex-row gap-4 items-center border rounded-lg p-6 bg-white w-full md:w-3/4 lg:w-1/2">
          {/* Image Section */}
          <div className="w-full md:w-1/2">
            <img
              src={images[currentIndex].src}
              alt={images[currentIndex].alt}
              className="rounded-lg h-auto max-w-full object-cover"
            />
          </div>
          {/* Text Section */}
          <div className="w-full md:w-1/2 text-left">
            <p className="mt-4 font-semibold text-lg">
              Name: {images[currentIndex].name}
            </p>
            <p className="text-sm text-gray-500 mb-4">
              Designation: {images[currentIndex].designation}
            </p>
            <p className="text-base">{images[currentIndex].text}</p>
          </div>
        </div>

        {/* Carousel buttons */}
        <button
          onClick={handlePrev}
          className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-primary text-white p-3 rounded-full shadow-md"
        >
          ❮
        </button>
        <button
          onClick={handleNext}
          className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-primary text-white p-3 rounded-full shadow-md"
        >
          ❯
        </button>
      </div>
    </div>
  );
};

export default Slider;
