import React from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../asset/logo/promote flare.png";

const Navber = () => {
  const menuItem = (
    <>
      <li>
        <Link to="/">Home</Link>
      </li>
      <li>
        <Link to="service">Services</Link>
      </li>
      <li>
        <Link to="/price">Pricing </Link>
      </li>
      {/* <li>
        <Link to="/blog">Blog</Link>
      </li> */}
      <li>
        <Link to="/aboutUs">About Us</Link>
      </li>
      <li>
        <Link to="/contractUs">Contact Us</Link>
      </li>
    </>
  );

  return (
    <div className="navbar lg:px-10  fixed w-full lg:top-5 z-10 lg:z-20 left-0 ">
      <div className="navbar  rounded-full lg:px-5   bg-white text-dark shadow-lg justify-between">
        <div className="navbar-start">
          <Link to="./">
            <p className="btn btn-ghost normal-case  text-2xl">
              <div className=" rounded-full">
                <img
                  alt="Tailwind CSS Navbar component"
                  src={logo}
                  style={{ width: "100%", height: "30px" }}
                />
              </div>
            </p>
          </Link>
        </div>
        <div className="navbar-end  lg:flex hide-after-600">
          <ul className="menu menu-horizontal  p-0">{menuItem}</ul>
        </div>
        <div className="dropdown dropdown-end z-10">
          <label tabIndex="0" className="btn btn-ghost  lg:hidden">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              className="inline-block text-black w-10 h-7  stroke-current"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              ></path>
            </svg>
          </label>
          <ul
            tabIndex="0"
            className="menu   menu-compact dropdown-content mt-3 p-2 shadow bg-base-100   rounded-box w-52"
          >
            {menuItem}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Navber;
