import React from "react";
import { useNavigate } from "react-router-dom";
import youtube from "./../../../asset/youtube.jpg";
import Facebook from "./../../../asset/fb.png";
import TikTok from "./../../../asset/tiktok.png";
import Snapchat from "./../../../asset/snap.png";
import Pinterest from "./../../../asset/printarest.jpg";
import Instagram from "./../../../asset/instagram.png";
import LinkedIn from "./../../../asset/lind.png";
import Twitter from "./../../../asset/twitter.jpg";
import Discord from "./../../../asset/Discord.jpg";
import Medium from "./../../../asset/medium.png";
import Apple from "./../../../asset/apple.png";
import Twitch from "./../../../asset/twitch.png";
import Quora from "./../../../asset/quora.png";
import Reddit from "./../../../asset/reddit.jpg";
import Telegram from "./../../../asset/Telegram.png";
import Spotify from "./../../../asset/spptify.webp";

const skills = [
  {
    category: "YouTube Services",
    name: "Youtube",
    image: youtube,
    id: 1,
    services: [
      "YouTube Channel Creation & Setup",
      "YouTube Video SEO & Metadata Optimization",
      "YouTube Content Creation",
      "YouTube Channel Growth Strategies",
      "YouTube Video & Channel Promotion",
      "YouTube Monetization Support",
      "YouTube Channel Management",
      "YouTube Analytics & Performance Tracking",
      "YouTube Video Optimization for Mobile",
      "YouTube Livestreaming Services",
      "YouTube Policies & Compliance",
      "YouTube Branding & Growth Consultation",
      "YouTube Affiliate Marketing & E-commerce Integration",
      "YouTube Add-On Services",
    ],
  },
  {
    category: "Podcast Services",
    name: "Podcast Services",
    image: Apple,
    id: 2,
    services: [
      "Apple Podcast Setup & Launch",
      "Podcast Content Creation & Editing",
      "Podcast SEO Optimization for Apple Podcasts",
      "Podcast Promotion & Marketing",
      "Podcast Analytics & Performance Tracking",
      "Podcast Monetization & Sponsorships",
      "Podcast Guest Management & Booking",
      "Podcast Episode Distribution & Syndication",
      "Podcast Graphic Design & Visuals",
      "Podcast PR & Outreach",
      "Podcast Audience Engagement & Community Building",
      "Podcast Sponsorship Ad Read Creation",
      "Podcast Add-On Services",
    ],
  },
  {
    category: "Facebook Services",
    name: "Facebook",
    image: Facebook,
    id: 3,
    services: [
      "Facebook Page Setup & Optimization",
      "Facebook Content Creation & Management",
      "Facebook Ads Campaign Management",
      "Facebook Page & Audience Growth",
      "Facebook Shop & Marketplace Integration",
      "Facebook Analytics & Performance Tracking",
      "Facebook Messenger Marketing",
      "Facebook Event Promotion",
      "Facebook Reputation Management (Review)",
      "Facebook Influencer Collaborations",
      "Facebook Lead Generation",
      "Facebook Video Marketing",
      "Facebook Custom Audiences & Retargeting",
      "Facebook Add-On Services",
    ],
  },
  {
    category: "Instagram Services",
    name: "Instagram",
    image: Instagram,
    id: 4,
    services: [
      "Instagram Account Setup & Optimization",
      "Instagram Content Creation",
      "Instagram Growth Strategy",
      "Instagram Ads Campaign Management",
      "Instagram Analytics & Performance Tracking",
      "Instagram Influencer Marketing",
      "Instagram Shop & Product Tagging",
      "Instagram Stories Management",
      "Instagram Reels & IGTV",
      "Instagram Engagement Boost",
      "Instagram Live Management",
      "Instagram Story Highlights Curation",
      "Instagram Contest & Giveaway Management",
      "Instagram Add-On Services",
    ],
  },
  {
    category: "TikTok Services",
    name: "TikTok",
    image: TikTok,
    id: 5,
    services: [
      "Account Creation & Setup",
      "Video Creation & Editing",
      "Hashtag Research & SEO",
      "Trend Analysis",
      "TikTok Ads",
      "Influencer Collaborations",
      "Community Engagement",
    ],
  },
  {
    category: "LinkedIn Services",
    name: "LinkedIn",
    image: LinkedIn,
    id: 6,
    services: [
      "Profile Optimization",
      "Content Creation",
      "Networking Strategy",
      "LinkedIn Ads",
      "Company Page Management",
      "LinkedIn Analytics",
    ],
  },
  {
    category: "Twitter Services",
    name: "Twitter",
    image: Twitter,
    id: 7,
    services: [
      "Profile Setup & Optimization",
      "Tweet Scheduling & Strategy",
      "Hashtag Research",
      "Twitter Ads",
      "Community Engagement",
      "Twitter Analytics",
    ],
  },
  {
    category: "Pinterest Services",
    name: "Pinterest",
    image: Pinterest,
    id: 8,
    services: [
      "Account Setup & Optimization",
      "Pin Creation & Design",
      "Pin Scheduling",
      "Pinterest SEO",
      "Pinterest Ads",
      "Analytics & Reporting",
    ],
  },
  {
    category: "Snapchat Services",
    name: "Snapchat",
    image: Snapchat,
    id: 9,
    services: [
      "Account Creation & Setup",
      "Snapchat Content Creation",
      "Snapchat Ads",
      "Influencer Collaborations",
      "Geofilters & Lenses",
      "Analytics & Performance Tracking",
    ],
  },
  {
    category: "Reddit Services",
    name: "Reddit",
    image: Reddit,
    id: 10,
    services: [
      "Account Setup & Subreddit Engagement",
      "Content Creation",
      "Reddit Ads",
      "AMA (Ask Me Anything) Coordination",
      "Moderation & Community Management",
      "Reddit Analytics",
    ],
  },
  {
    category: "Quora Services",
    name: "Quora",
    image: Quora,
    id: 11,
    services: [
      "Profile Optimization",
      "Question Answering",
      "Content Strategy",
      "Quora Ads",
      "Analytics & Reporting",
    ],
  },
  {
    category: "Twitch Services",
    name: "Twitch",
    image: Twitch,
    id: 12,
    services: [
      "Channel Creation & Setup",
      "Stream Overlays & Alerts",
      "Stream Scheduling & Promotion",
      "Monetization Setup",
      "Community Engagement",
      "Twitch Ads",
    ],
  },
  {
    category: "Medium Services",
    name: "Medium",
    image: Medium,
    id: 13,
    services: [
      "Profile Setup & Optimization",
      "Article Writing",
      "Publication Submissions",
      "Content Strategy",
      "Monetization Support",
      "Analytics & Reporting",
    ],
  },
  {
    category: "Spotify / Apple Podcasts / Google Podcasts Services",
    name: "Spotify",
    image: Spotify,
    id: 14,
    services: [
      "Podcast Setup",
      "Podcast Editing",
      "Podcast Marketing",
      "Monetization & Sponsorships",
      "Podcast SEO",
      "Analytics & Reporting",
    ],
  },
  {
    category: "Discord Services",
    name: "Discord",
    image: Discord,
    id: 15,
    services: [
      "Server Setup & Customization",
      "Bot Integration",
      "Community Management",
      "Discord Growth",
      "Server Analytics",
    ],
  },
  {
    category: "Telegram Services",
    name: "Telegram",
    image: Telegram,
    id: 16,
    services: [
      "Channel Creation & Management",
      "Content Creation & Scheduling",
      "Bot Integration",
      "Community Engagement",
      "Analytics & Reporting",
    ],
  },
];

const FocusArea = () => {
  const navigate = useNavigate();
  const goDetails = (data) => {
    navigate(`/details/${data.id}`, { state: data });
    // console.log(data);
  };
  return (
    <div className="py-12 lg:px-12 p-5">
      <h1 className="text-2xl  md:text-4xl lg:text-5xl  font-bold text-center text-primary pb-8">
        Our key focus areas
      </h1>
      <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-10">
        {skills.map((skill, index) => (
          <div
            onClick={() => goDetails(skill)}
            key={index}
            className="text-center hover py-4 bg-base-100 rounded-xl shadow transform hover:scale-105 transition-transform duration-300 hover:shadow-3xl "
          >
            <div className="avatar">
              <div className="w-24 h-24 rounded-full">
                <img src={skill.image} alt={skill.name} />
              </div>
            </div>
            <h2 className="text-2xl font-bold pb-3">{skill.name}</h2>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FocusArea;
