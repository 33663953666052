import logo from "./logo.svg";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./Page/Home/Home";
import About from "./Page/Home/About";
import Navber from "./Page/Shared/Navber";
import AllSkill from "./Page/Home/AllSkill/AllSkill";
import HandTool from "./Page/Home/HandTool/HandTool";
import Car from "./Page/Home/Car/Car";
import Tutor from "./Page/Home/Tutor/Tutor";
import Blog from "./Page/Home/Blog";
import DetailsOfMedia from "./Page/components/detailsOfMedia/DetailsOfMedia";
import Services from "./Page/components/services/services";
import Contract from "./Page/components/contract/Contract";
import AboutUs from "./Page/components/home/about/AboutUs";
import Footer from "./Page/Shared/Footer";
import ContractUs from "./Page/components/home/contract/ContractUs";
import PricePage from "./Page/components/price/PricePage";

function App() {
  return (
    <div
      style={{ backgroundColor: "#dcf7f3" }}
      className="mx-auto max-w-screen-xl "
    >
      <Navber></Navber>
      <Routes>
        <Route path="/" element={<Home></Home>}></Route>
        <Route path="/aboutUs" element={<AboutUs />}></Route>
        <Route path="/blog" element={<Blog></Blog>}></Route>
        <Route path="/service" element={<Services />}></Route>
        <Route path="/contract" element={<Contract />}></Route>
        <Route path="/contractUs" element={<ContractUs />}></Route>
        <Route path="/price" element={<PricePage />}></Route>

        <Route path="/details/:unicId" element={<DetailsOfMedia />}></Route>
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
