import React from "react";

const PricePage = () => {
  return (
    <div className="h-96 py-10 ">
      <div className="px-10 py-10">
        <p className="my-10 py-10 pl-10">Coming soon ...</p>
      </div>
    </div>
  );
};

export default PricePage;
