import React, { useRef } from "react";
import emailjs from "@emailjs/browser";

const ContractUs = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_g8b86ap",
        "template_6bg6g9u",
        form.current,
        "PX9bxfsHdd3VI7tuB"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Send Your All Things");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <div className="lg:px-12 py-10">
      <div className="grid lg:text-medium md:grid-cols-2 lg:grid-cols-2 gap-32">
        <div className="py-10 my-10 text-justify">
          <h1 className="text-2xl">Contract Us ...</h1>
          <p>
            Promote Flare is your trusted partner for driving business success
            through innovative digital marketing and dynamic social media
            strategies. Our team of experts crafts tailored campaigns designed
            to boost brand awareness, increase engagement, and convert leads
            into loyal customers. Whether you're looking to enhance your online
            presence or accelerate business growth, Promote Flare delivers
            impactful, data-driven solutions that bring measurable results.
            Let’s ignite your brand’s digital potential!
          </p>
          <div className="text-primary pt-3">
            <p className="">abdurrazzaq583@gmail.com</p>
            <p className="">Rajshahi, Bangladesh</p>
            <p className="">Phone : 01945136820</p>
          </div>
        </div>

        <div className="my-10 py-10">
          <h1 className="text-2xl text-center pt-5">
            DON'T HESITATE TO CONTACT...!
          </h1>
          <div className="shadow-xl rounded-2xl p-5 pt-10">
            <form ref={form} onSubmit={sendEmail}>
              <label>Name</label>
              <input
                className="input mb-1 input-bordered w-full"
                type="text"
                name="name"
                placeholder="Your Name"
              />
              <label>Email</label>
              <input
                className="input mb-1  input-bordered w-full"
                type="email"
                name="email"
                placeholder="Your Email"
              />
              <label>Mobile </label>
              <input
                className="input mb-1  input-bordered w-full"
                type="text"
                name="number"
                placeholder="Your Number"
              />
              <label>Message</label>
              <textarea
                className="input h-20 mb-3 input-bordered w-full"
                name="message"
                placeholder="Write Message"
              />
              <input
                className="btn btn-secondary w-full"
                type="submit"
                value="Send"
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContractUs;
