import React from "react";
import { Link, useLocation } from "react-router-dom";
import checkImage from "./../../asset/check.jpg";

const DetailsOfMedia = () => {
  const { state: data } = useLocation();

  console.log("getData", data);

  return (
    <div
      style={{ height: "100vh" }}
      className="hero pt-10 bg-base-500 min-h-screen"
    >
      <div
        style={{ height: "100vh" }}
        className="hero-content pt-9 mt-10 flex-col gap-10 lg:flex-row"
      >
        <img
          src={data.image}
          width="200"
          className="max-w-sm rounded-lg shadow-2xl"
          alt="Media representation"
        />
        <div>
          <p className="lg:text-3xl font-bold">
            We focus on delivering top-notch services across
          </p>
          <h1 className="lg:text-2xl font-bold">{data.category}</h1>
          <ul className="lg:text-2xl list-disc pl-5 space-y-2">
            {data.services.map((item, index) => (
              <li key={index} className="flex items-start">
                {/* Unique symbol before each list item */}
                <span className="mr-2 text-green-500">
                  <img src={checkImage} width="30" alt="" />
                </span>{" "}
                {/* Checkmark icon */}
                {item}
              </li>
            ))}
          </ul>
          <Link to="/service">
            <button
              style={{ backgroundColor: "#06e0e0" }}
              className="btn text-white  mt-5"
            >
              Get Started
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DetailsOfMedia;
