import React from "react";
import digital from "../asset/service/digital-marketing.png";
import social from "../asset/service/social-media.png";
import seo from "../asset/service/seo.png";
import { Link } from "react-router-dom";

const projectData = [
  {
    id: 1,
    title: "Digital Marketting",
    description:
      "It is used, HTML, CSS, JS, Bootstrap, React, Node, MongoDb, Firebase",
    image: digital,
    link: "/handTools",
  },
  {
    id: 3,
    title: "Search Engine Optimization",
    description: "It is used, HTML, CSS, JS, Bootstrap, React",
    image: seo,
    link: "/tutor",
  },
  {
    id: 2,
    title: "Social Media Marketting",
    description:
      "It is used, HTML, CSS, JS, Bootstrap, React, Node, MongoDb, Firebase",
    image: social,
    link: "/car",
    style: { paddingTop: "20px", width: "90%" },
  },
];

const Project = () => {
  return (
    <div id="project" className="lg:px-12 p-5 ">
      <h1
        style={{ color: "#390593" }}
        className="text-center font-bold  text-3xl md:text-4xl lg:text-5xl my-10"
      >
        PromoteFlare Marketing Solutions
      </h1>
      <div className=" grid  text-center lg:font-medium md:grid-cols-2 lg:grid-cols-3 gap-12">
        {projectData.map((project) => (
          <div key={project.id} className="card hover bg-base-100 shadow-xl">
            <figure>
              <img
                src={project.image}
                style={project.style}
                alt={project.title}
              />
            </figure>
            <div className="card-body">
              <h2 className="text-xl font-bold text-center">{project.title}</h2>

              {/* <p>{project.description}</p> */}
              {/* <div className="card-actions justify-center">
                <Link to={project.link}>
                  <button className="btn font-bold btn-primary">
                    Go To Details
                  </button>
                </Link>
              </div> */}
            </div>
          </div>
        ))}
      </div>
      {/* <p className="text-right text-2xl p-3 hover:underline font-bold">
        <Link to="/allProject">
          <button className="btn btn-secondary justify-end">
            See more project
          </button>
        </Link>
      </p> */}
    </div>
  );
};

export default Project;
