import React from "react";
import "./Banner.css";
import Typed from "react-typed";
import img from "../Home/hero/hero2.png";

const Banner = () => {
  return (
    <div>
      <div className="banner lg:z-0 lg:px-12  text-white p-5">
        <div className="hero-content flex-col-reverse md:flex-col-reverse lg:flex-row-reverse">
          <img
            className="w-6/6 md:w-4/6 lg:w-2/5 mx-auto m-10"
            src={img}
            alt=""
          />
          <div className="w-6/6 md:w-4/6 lg:w-4/7 mb-10 lg:pt-10 lg:mt:10">
            <h1 className="lg:text-6xl sm:text-2xl  font-bold sp-10m:">
              Boost Your Brand with
            </h1>
            <div
              style={{ color: "#C1F264" }}
              className="mt-3  lg:text-7xl   text-2xl lg:text-3xl font-bold"
            >
              <Typed
                strings={["Promote Flare"]}
                typeSpeed={100}
                backSpeed={100}
                loop
              />
            </div>
            <h1 className="lg:text-6xl lg:hidden font-bold sp-10m:">
              Boost Your Brand with
            </h1>
            <p className="py-6 text-white lg:font-medium text-justify">
              At PromoteFlare, we help businesses grow by managing and
              optimizing their presence across all major social media platforms.
              Whether it’s Instagram, TikTok, Facebook, LinkedIn, or Twitter –
              we offer tailored services like Content Creation, Ad Campaigns,
              and Analytics & Reporting to enhance your brands visibility. Ready
              to take your social media to the next level? Let’s get started
              today!
            </p>
            {/* 
            <a
              href="https://drive.google.com/file/d/1v7i5Y0M_CKpssUveEy1umLvqIzdOD_TV/view"
              target="_blank"
            >
              <button className="btn mt-5 md:mt-5 lg:mt-5  mb-5 btn-secondary font-bold text-primary">
                see my resume
              </button>
            </a> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
