import React from "react";

const Contract = () => {
  return (
    <div style={{ height: "100vh" }} className="lg:px-12 p-5">
      <h1 className="text-2xl mt-10 pt-10">Comming soon .........</h1>
    </div>
  );
};

export default Contract;
