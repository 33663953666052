import React from "react";
// import tools from "./Project/img/tools.png";
import student from "./student.png";
import car from "./car.png";
import { Link } from "react-router-dom";

const projectData = [
  {
    id: 1,
    title: "HAND TOOL",
    description:
      "It is used, HTML, CSS, JS, Bootstrap, React, Node, MongoDb, Firebase",
    image: student,
    link: "/handTools",
  },
  {
    id: 2,
    title: "Car-Web",
    description:
      "It is used, HTML, CSS, JS, Bootstrap, React, Node, MongoDb, Firebase",
    image: car,
    link: "/car",
  },
  {
    id: 3,
    title: "Tutor",
    description: "It is used, HTML, CSS, JS, Bootstrap, React",
    image: student,
    link: "/tutor",
  },
];

const Project = () => {
  return (
    <div id="project" className="lg:px-12 p-5">
      <h1 className="text-center font-bold text-primary text-3xl md:text-4xl lg:text-5xl uppercase my-10">
        This is our project
      </h1>
      <div className="grid lg:font-medium md:grid-cols-2 lg:grid-cols-3 gap-12">
        {projectData.map((project) => (
          <div key={project.id} className="card bg-base-100 shadow-xl">
            <figure>
              <img src={project.image} alt={project.title} />
            </figure>
            <div className="card-body">
              <h2 className="card-title">{project.title}</h2>
              <p>{project.description}</p>
              <div className="card-actions justify-end">
                <Link to={project.link}>
                  <button className="btn font-bold btn-link">
                    Go To Details
                  </button>
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* <p className="text-right text-2xl p-3 hover:underline font-bold">
        <Link to="/allProject">
          <button className="btn btn-secondary justify-end">
            See more project
          </button>
        </Link>
      </p> */}
    </div>
  );
};

export default Project;
